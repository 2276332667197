
import React, { useState, useEffect } from 'react';
import LogoIcon from '../assets/TDA__Logo_Icon_White.png';

const Stage3TaxCutsCalculator = () => {
  const [income, setIncome] = useState('0');
  const [result, setResult] = useState('With an income of $_____, your tax savings under the Stage 3 tax cuts would be $_____.');
  const [totalTax, setTotalTax] = useState('This means you\'ll be paying a total amount of $_____ tax in the 2024-2025 financial year (without considering any tax deductions).');
  const [customAmount, setCustomAmount] = useState('');
  const [showCustomInput, setShowCustomInput] = useState(false);

  const calculateTax2023 = (income) => {
    if (income <= 18200) {
      return 0;
    } else if (income <= 45000) {
      return 0.19 * (income - 18200);
    } else if (income <= 120000) {
      return 5092 + 0.325 * (income - 45000);
    } else if (income <= 180000) {
      return 29467 + 0.37 * (income - 120000);
    } else {
      return 51667 + 0.45 * (income - 180000);
    }
  };

  const calculateTax2024 = (income) => {
    if (income <= 18200) {
      return 0;
    } else if (income <= 45000) {
      return 0.16 * (income - 18200);
    } else if (income <= 135000) {
      return 4288 + 0.30 * (income - 45000);
    } else if (income <= 190000) {
      return 31288 + 0.37 * (income - 135000);
    } else {
      return 51638 + 0.45 * (income - 190000);
    }
  };

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleSliderChange = (e) => {
    const value = e.target.value;
    setIncome(value);
    setCustomAmount(''); // Clear custom amount when slider is used
    setShowCustomInput(false); // Hide custom input when slider is used
  };

  const handleCustomAmountChange = (e) => {
    const rawValue = e.target.value.replace(/,/g, '');
    if (!isNaN(rawValue) && rawValue.length <= 15) {
      setCustomAmount(rawValue);
      setIncome(rawValue);
    }
  };

  useEffect(() => {
    const parsedIncome = parseInt(income.replace(/,/g, ''), 10);
    if (!isNaN(parsedIncome)) {
      const tax2023 = calculateTax2023(parsedIncome);
      const tax2024 = calculateTax2024(parsedIncome);
      const savings = tax2023 - tax2024;

      setResult(
        <h3>With an annual income of <span className="yellow-text">${formatNumberWithCommas(parsedIncome)}</span>, your tax savings under the Stage 3 tax cuts would be <span className="yellow-text">${formatNumberWithCommas(savings)}</span>.</h3>
      );
      setTotalTax(
        <h3>This means you'll be paying a total amount of <span className="yellow-text">${formatNumberWithCommas(tax2024)}</span> tax in the 2024-2025 financial year (without considering any tax deductions).</h3>
      );
    } else {
      setResult('With an income of $_____, your tax savings under the Stage 3 tax cuts would be $_____.');
      setTotalTax('This means you\'ll be paying a total amount of $_____ tax in the 2024-2025 financial year (without considering any tax deductions).');
    }
  }, [income]);

  const toggleCustomInput = () => {
    setShowCustomInput(!showCustomInput);
  };

  return (
    <div className="calculator-container">
      <h3 className="h2taxcut">Your annual income: <span className="yellow-text">${formatNumberWithCommas(income)}</span></h3>
      <div className="input-container">
        <input
          type="range"
          id="incomeSlider"
          min="0"
          max="300000"
          step="1000"
          value={income.replace(/,/g, '')}
          onChange={handleSliderChange}
        />
      </div>
      <p className="custom-amount-toggle" onClick={toggleCustomInput}>
          or click here to enter a custom amount
      </p>
      {showCustomInput && (
        <div className="input-container">
          <input
            type="text"
            id="customAmount"
            placeholder="Enter custom amount"
            value={customAmount}
            onChange={handleCustomAmountChange}
          />
        </div>
      )}
      <div className="split-container">
        <h3 id="result">{result}</h3>
        <h3 id="totalTax">{totalTax}</h3>
        <h3>The Stage 3 tax cuts take effect in the 2024-25 financial year, offering savings compared to the 2023-24 tax rates. This calculator helps you determine your tax savings.</h3>
      </div>
      <div className="logo-icon-wrapper">
        <img className="logo-icon" src={LogoIcon} alt="TDA Logo Icon" />
        <p style={{ paddingTop: '8px' }}>&copy; 2024 All rights reserved.</p>
      </div>
    </div>
  );
};

export default Stage3TaxCutsCalculator;
