
import React, { useState, useEffect } from 'react';
import LogoIcon from '../assets/TDA__Logo_Icon_White.png';

const HecsDebtCalculator = () => {
  const [hecsDebt, setHecsDebt] = useState(0);
  const [extraOwe, setExtraOwe] = useState('');
  const [newHecsDebt, setNewHecsDebt] = useState('');
  const [customAmount, setCustomAmount] = useState('');
  const [showCustomInput, setShowCustomInput] = useState(false);

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    const parsedHecsDebt = parseFloat(hecsDebt);
    if (!isNaN(parsedHecsDebt)) {
      const increase = parsedHecsDebt * 0.047;
      setExtraOwe(formatNumber(increase.toFixed(0)));
      setNewHecsDebt(formatNumber((parsedHecsDebt + increase).toFixed(0)));
    } else {
      setExtraOwe('');
      setNewHecsDebt('');
    }
  }, [hecsDebt]);

  const handleSliderChange = (e) => {
    const value = e.target.value;
    setHecsDebt(value);
    setCustomAmount('');
    setShowCustomInput(false);
  };

  const handleCustomAmountChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    if (!isNaN(value) && value !== '') {
      setCustomAmount(value);
      setHecsDebt(value);
    } else {
      setCustomAmount('');
      setHecsDebt(0);
    }
  };

  const toggleCustomInput = () => {
    setShowCustomInput(!showCustomInput);
  };

  return (
    <div className="calculator-container">
      <div className="split-container">
        <h3>Your HECS rose by <span className="yellow-text">4.7% today.</span></h3>
        <h3>Move the slider to how much you owe, or enter a custom amount below, and we'll show you how much it has risen by.</h3>
        <div className="input-group">
          <h3>
            <label htmlFor="hecsDebt">
              Your HECS debt as at 31 May 2024 <span className="yellow-text">${formatNumber(hecsDebt)}.</span>
            </label>
          </h3>
          <div className="input-container">
            <input
              type="range"
              id="hecsDebt"
              min="0"
              max="100000"
              step="1000"
              value={hecsDebt}
              onChange={handleSliderChange}
            />
          </div>
          <p className="custom-amount-toggle" onClick={toggleCustomInput}>
            Click here to enter a custom amount
          </p>
          {showCustomInput && (
            <div className="input-container">
              <input
                type="text"
                id="customAmount"
                placeholder="Enter custom amount"
                value={customAmount}
                onChange={handleCustomAmountChange}
              />
            </div>
          )}
          <h3>
            The extra you owe: <span className="yellow-text">${extraOwe ? extraOwe : '_____'}.</span>
          </h3>
          <h3>
            Your HECS debt as at 1 June 2024: <span className="yellow-text">${newHecsDebt ? newHecsDebt : '_____'}.</span>
          </h3>
        </div>
      </div>
      <div className="logo-icon-wrapper">
        <img className="logo-icon" src={LogoIcon} alt="TDA Logo Icon" />
        <p style={{ paddingTop: '8px' }}>&copy; 2024 All rights reserved.</p>
      </div>
    </div>
  );
};

export default HecsDebtCalculator;
