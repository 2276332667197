import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
    return (
        <div>
                <div className="single-column">
                <h1>Calculators</h1>
                <Link to="/hecs-debt-calculator">
                    <button className="green-btn">Hecs debt calculator</button>
                </Link>
                <Link to="/stage-3-tax-cuts-calculator">
                    <button className="green-btn">Stage 3 tax cuts calculator</button>
                </Link>
                <h2>Links</h2>
                <button className="muted-btn" onClick={() => window.open('https://thedailyaus.com.au', '_blank', 'noopener,noreferrer')}>Website</button>
                <button className="muted-btn" onClick={() => window.open('https://www.instagram.com/thedailyaus/?hl=en', '_blank', 'noopener,noreferrer')}>Instagram</button>
                <button className="muted-btn" onClick={() => window.open('https://www.newsletter.thedailyaus.com.au/', '_blank', 'noopener,noreferrer')}>Daily newsletter</button>
                <button className="muted-btn" onClick={() => window.open('https://sport.thedailyaus.com.au/', '_blank', 'noopener,noreferrer')}>Sport newsletter</button>
                <button className="muted-btn" onClick={() => window.open('https://picturethis.thedailyaus.com.au/', '_blank', 'noopener,noreferrer')}>Picture This</button>
                <button className="muted-btn" onClick={() => window.open('https://www.tiktok.com/@thedailyaus?lang=en', '_blank', 'noopener,noreferrer')}>TikTok</button>
                <button className="muted-btn" onClick={() => window.open('https://www.youtube.com/c/thedailyaus', '_blank', 'noopener,noreferrer')}>YouTube</button>
                <iframe 
                    title="Podcast Embed"
                    style={{ borderRadius: '12px' }} 
                    src="https://open.spotify.com/embed/show/0onVY7weTsqjZLM8y3Tt9A?utm_source=generator" 
                    width="100%" 
                    height="152"
                    frameBorder="0" 
                    allowFullScreen 
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                    loading="lazy">
                </iframe>
            </div>
        </div>
    );
};

export default Dashboard;

