import React from 'react';
import Stage3TaxCalculator from '../components/Stage3TaxCutsCalculator.js';

const Stage3TaxCutsCalculatorScreen = () => {
    return (
        <div className="screencontent">
            <h1>Calculate your tax savings with Stage 3 tax cuts</h1>
            <Stage3TaxCalculator />
        </div>
    );
};

export default Stage3TaxCutsCalculatorScreen;