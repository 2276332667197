
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import Dashboard from './screens/Dashboard.js';
import HecsDebtCalculator from '../src/screens/HecsDebtCalculatorScreen.js';
import Stage3TaxCalculator from '../src/screens/Stage3TaxCutsCalculatorScreen.js';
import './index.css';
import logo from '../src/assets/TDA-Logo-Secondary_White.svg';
import Mindblown from '../src/assets/TDA_Mind-Blowing.png';

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const closeSidebar = useCallback(() => {
    if (sidebarOpen) {
      setSidebarOpen(false);
    }
  }, [sidebarOpen]);

  useEffect(() => {
    const handleTouchStart = (e) => {
      const touchStartX = e.touches[0].clientX;
      sidebarRef.current.touchStartX = touchStartX;
    };

    const handleTouchEnd = (e) => {
      const touchEndX = e.changedTouches[0].clientX;
      const touchStartX = sidebarRef.current.touchStartX;
      if (touchStartX - touchEndX > 50) {
        closeSidebar();
      }
    };

    const sidebarElement = sidebarRef.current;
    if (sidebarElement) {
      sidebarElement.addEventListener('touchstart', handleTouchStart);
      sidebarElement.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      if (sidebarElement) {
        sidebarElement.removeEventListener('touchstart', handleTouchStart);
        sidebarElement.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [closeSidebar]);

  return (
    <Router>
      <div className="app">
        <nav ref={sidebarRef} className={`sidebar ${sidebarOpen ? 'active' : ''}`}>
          <div className="sidebar-container"><h1>Knowledge Hub</h1></div>
          <span className="material-icons close-icon" onClick={closeSidebar}>close</span>
          <ul>
            <SidebarItem to="/" label="Home" toggleSidebar={toggleSidebar} />
            <SidebarItem to="/hecs-debt-calculator" label="HECS debt calculator" toggleSidebar={toggleSidebar} />
            <SidebarItem to="/stage-3-tax-cuts-calculator" label="Stage 3 tax cuts calculator" toggleSidebar={toggleSidebar} />
          </ul>
          <div className="mindblown-wrapper">
            <img className="mindblown" src={Mindblown} alt="Mindblown" />
            <img className="logo" src={logo} alt="The Daily Aus Logo" />
            <p style={{ paddingTop: '8px' }}>&copy; 2024 All rights reserved.</p>
          </div>
        </nav>
        <div className={`mobile-navigation-wrapper ${sidebarOpen ? 'blurred' : ''}`}>
          <span className="material-icons hamburger" onClick={toggleSidebar}>menu</span>
          <img className="nav-logo" src={logo} alt="The Daily Aus Logo" />
          
        </div>
        <div className={`main-content ${sidebarOpen ? 'blurred' : ''}`} onClick={closeSidebar}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/hecs-debt-calculator" element={<HecsDebtCalculator />} />
            <Route path="/stage-3-tax-cuts-calculator" element={<Stage3TaxCalculator />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

const SidebarItem = ({ to, label, toggleSidebar }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => (isActive ? 'active' : '')}
      onClick={toggleSidebar}
    >
      <li>{label}</li>
    </NavLink>
  );
};

export default App;
