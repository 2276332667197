import React from 'react';
import HecsDebtCalculator from '../components/HecsDebtCalculator.js';

const HecsDebtCalculatorScreen = () => {
    return (
        <div>
            <div className="container"><h1>HECS debt calculator</h1></div>
            <HecsDebtCalculator />
        </div>
    );
};

export default HecsDebtCalculatorScreen;